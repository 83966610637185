<template>
  <div class="home">
    <!-- <div class="banner-bg">
      <img :src="bannerBgImg" />
      
    </div> -->
    <div class="my-page">
      <template v-if="cloudClassrooms && cloudClassrooms.length > 0">
        <div class="my-title">
          <div class="title-label">
            <span class="span">云课堂</span>
            <span class="line"></span>
          </div>
          <div class="title-go" style="z-index:100" @click="goNoticeAll">查看全部 ></div>
        </div>
        <div
          class="my-card"
          v-for="(childrenItem, i) in cloudClassrooms"
          :key="i"
          @click="goNoticeView(childrenItem)"
        >
          <div class="card-main">
            <div class="card_left">
              <p class="title">{{ childrenItem.Title }}</p>
              <p class="desc">{{ childrenItem.Desc }}</p>
              <div>
                <span v-if="childrenItem.LabelName" class="label">{{
                  childrenItem.LabelName
                }}</span>
                <span class="date">{{ $moment(childrenItem.CreateDate).format("YYYY-MM-DD") }}</span>
              </div>
            </div>
            <div class="card_right">
              <img
                :src="
                  childrenItem.TitleImage ? childrenItem.TitleImage : defaultImg
                "
                alt=""
              />
            </div>
          </div>
        </div>
      </template>
      <van-empty description="暂无数据" v-else />
    </div>
  </div>
</template>
<script>
import bannerImg from "@/assets/img/cloudClass.png";
import bannerBgImg from "@/assets/home/CloudBanner.png";
import defaultImg from "@/assets/img/defaultImg.png";
import { Empty } from "vant";
import { Swipe, SwipeItem } from "vant";
export default {
  components: {
    [Empty.name]: Empty,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      defaultImg: defaultImg,
      bannerBgImg: bannerBgImg,
      userInfo: JSON.parse(window.localStorage.getItem("userInfo")),
      userType: window.localStorage.getItem("UserType"),
      bannerImg: bannerImg,
      cloudClassrooms: [],
    };
  },
  mounted() {
    // 获取通知公告
    this.getParentSchool();
  },
  methods: {
    getParentSchool() {
      this.$axios
        .get("/api/CloudClassroom/cloudClassroom-listforapp", {
          page: 1,
          limit: 6,
        })
        .then((res) => {
          this.cloudClassrooms = res.data;
        });
    },
    goNoticeAll() {
      this.$router.push({
        path: "CloudClassroomList"
      });
    },
    goNoticeView(item) {
      this.$router.push({
        path: "CloudClassroomView",
        query: {
          id: item.Id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
.van-swipe-item {
  margin: 0px 10px;
}
</style>
